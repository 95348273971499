@use '@angular/material' as mat;

@import "@angular/material/prebuilt-themes/indigo-pink.css";


@include mat.core();

@font-face {
  font-family: 'museo';
  src: url(/src/assets/font/300-museo-96220/Museo300-Regular.otf) format("otf"),
    url(/src/assets/font/500-museo-96230/Museo500-Regular.otf) format("otf"),
    url(/src/assets/font/700-museo-96206/Museo700-Regular.otf) format("otf");
  font-family: 'MuseoSans';
  src: url(/src/assets/font/300-museo-sans-94979/MuseoSans_300.otf) format("otf"),
    url(/src/assets/font//500-museo-sans-95049/MuseoSans_500.otf) format("otf"),
    url(/src/assets/font/700-museo-sans-94972/MuseoSans_700.otf) format("otf");
}

;


$raffinger-palette: (
  50: #e7f6f7,
  100: #c2e8ec,
  200: #3BB3BE,
  300: #70cad2,
  400: #52bec8,
  500: #33b3be,
  600: #2eacb8,
  700: #27a3af,
  800: #209aa7,
  900: #148b99,
  A100: #cdf9ff,
  A200: #3BB3BE,
  A400: #67ecff,
  A700: #4de9ff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$my-primary: mat.define-palette($raffinger-palette, 500);
$my-accent: mat.define-palette($raffinger-palette, A200, A100, A400);



$my-theme: mat.define-light-theme((color: (primary: $my-primary,
        accent: $my-accent,
      ),
      typography: mat.define-typography-config($font-family: "'MuseoSans','museo'"),
      density: 0,
    ));

@include mat.all-component-themes($my-theme);